<template>
  <b-modal 
    v-model="show"
    class="text-center"
    centered
    title="Top 3 videos"
    hide-footer
    size="xl"
  >
    <b-row class="match-height">
      <b-col
        cols="4"
        v-for="video in videos"
        :key="video.id.videoId"
      >
        <b-card img-top no-body>
          <div class="contain-preview">
            <b-card-img :height="355" :src="video.snippet.thumbnails.high.url"/>
            <b-avatar class="btn" rounded variant="secondary" button @click="redirect_video(video.id)">
              <feather-icon
                icon="PlayIcon"
                class="text-light"
              />
            </b-avatar>
          </div>
          <b-card-body>
            <b-card-text>
              <small class="text-muted">{{ getFormatDate(video.snippet.publishedAt) }}</small>
            </b-card-text>
            <b-card-text>
              {{ video.snippet.title }}
              <small class="text-muted">({{ utils.formatDuration(video.contentDetails.duration) }})</small>
            </b-card-text>
            <b-card-text>
              {{ video.snippet.description }}
            </b-card-text>
          </b-card-body>
          <b-card-footer class="p-1">
            <b-row  class="justify-content-around align-items-center">
              <b-col cols="6">
              </b-col>
              <b-col cols="4">
                <bar-metrics :likes="video.statistics.likeCount" :comments="video.statistics.commentCount" :views="video.statistics.viewCount" :dislikes="video.statistics.dislikeCount"></bar-metrics>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="text-right">
        <b-button variant="primary" @click="redirectChannel()">Ir a perfil</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { BModal, BButton, BRow, BCol, BCard, BCardBody, BCardText, BCardImg, BAvatar, BCardFooter } from 'bootstrap-vue'
import BarMetrics from '@/views/components/media/BarMetrics'
import utils from '@/libs/utils'

export default {
  name: 'ModalVideosYoutube',
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    videos: {
      type: Array,
      default: () => []
    },
  },
  components: {
    BModal,
    BCard,
    BCardImg,
    BAvatar,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BCardFooter,
    BarMetrics,
    BButton
  },
  data () {
    return {
      utils,
      show: this.modalShow,
      channel_id: ''
    }
  },
  created() {
    this.channel_id = this.videos[0].snippet.channelId
  },
  methods: {
    redirectChannel() {
      const url = `https://www.youtube.com/channel/${this.channel_id}`
      window.open(url, '_blank')
    },
    redirect_video(video_id) {
      const url = `https://www.youtube.com/watch?v=${video_id}`
      window.open(url, '_blank')
    },
    getFormatDate(date_string) {
      return utils.getDatev2(new Date(date_string))
    }
  }
}
</script>

<style lang="scss" scoped>

.contain-preview {
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .btn:hover {
    background-color: black;
  }
}
</style>