<template>
  <div>
    <b-card
      header-class="color-header text-header"
      class="cointaner-card"
      header-text-variant="white"
    >
      <template #header>
        <span>Creator Pro</span>
      </template>
      <div class="container-data">
        <div>
          <span class="text-starting">Starting at</span>

          <span class="text-price">$6</span>
          <span class="text-month">Month</span>
          <hr />
          <div class="d-flex flex-column">
            <b-badge pill class="text-plan">All Basic features plus:</b-badge>
            <span class="text-pitch"
              ><b-icon class="icon-star" icon="star-fill" /> Pitch unlimited
              casting calls</span
            >
            <span class="text-check"
              ><b-icon icon="check" /> Guaranteed Traffic</span
            >
            <span class="text-check"
              ><b-icon icon="check" /> Social & Performance Analytics</span
            >
            <span class="text-check"><b-icon icon="check" />Pro Badge</span>
            <span class="text-check"
              ><b-icon icon="check" /> Priority Admin Approval</span
            >
            <hr />
            <span class="list-secondary">10% Seller Service Fee</span>
            <span class="list-secondary">30 Day Seller Payout</span>
            <b-button disabled class="button-plan" variant="button-plan"
              >Update Plan</b-button
            >
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
  
<script>
import { BCard, BBadge, BIcon, BButton } from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BCard,
    BIcon,
    BButton,
  },

  created() {},
};
</script>
<style>
.text-header {
  font-weight: bold;
  color: white;
}
.button-plan {
  height: 3.125rem;
  background: #73205e;
  color: white;
  margin-top: 20px;
}
.list-secondary {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-pitch {
  font-weight: bold;
  margin-top: 10px;
}
.text-check {
  margin-top: 10px;
}
.icon-star {
  width: 15px;
  height: 15px;
  color: #fcd147 !important;
}
.text-plan {
  background: #73205e;
  font-size: 0.75em;
  margin-left: 45px;
  margin-right: 45px;
  font-weight: normal;
  padding: 5px;
}
.text-month {
  font-size: 0.85rem !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-price {
  font-size: 2.1875rem;
  font-weight: bold;
  margin-right: 5px;
}
.text-starting {
  display: block;
  text-transform: uppercase;
  color: #6c757d;
  font-size: 0.8rem;
  margin-top: 30px;
}
.container-data {
  text-align: center !important;
  margin: 0px;
}
.color-header {
  background: linear-gradient(to left bottom, #743eb5, #421150);
  justify-content: center !important;
  padding: 17px !important;
}
.cointaner-card {
  border: 1px solid #73205e;
  margin: 20px;
}
</style>