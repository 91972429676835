<template>
  <div>
    <b-card>
      <b-card-title>{{ $t("interests") }}</b-card-title>

      <v-select
        :filter="search"
        :placeholder="$t('mediaKits.category')"
        v-model="value"
        multiple
        :options="options"
        :selectable="() => value.length < 5"
        @option:selected="selected"
        @option:deselected="deselected"
      >
        <template #selected-option="{ value }">
          <span>{{ $t(`mediaKits.${value}`) }}</span>
        </template>
        <template slot="option" slot-scope="option">
          {{ $t(`mediaKits.${option.label}`) }}
        </template>
      </v-select>
    </b-card>

    <div class="mt-2">
      <b-card-title>{{ $t("history") }}</b-card-title>
      <b-card-text>{{ $t("addColab") }}</b-card-text>
      <b-row class="mt-2">

        <b-col cols="12">
          <div v-if="optionsNets.length === 0 && loaded" class="alert alert-warning message-social-add" role="alert">
            <div>
              <feather-icon icon="InfoIcon" class="mr-1" size="20"/>
                {{ $t("alertDisconnected") }}</div> 
              <div class="size-text-msj"> 
                {{ $t("alertDisconnectedSecondary") }}
            </div>
          </div>
        </b-col>

        <b-col cols="12" class="col-md-9 col-lg-9 col-xl-6">
         
          <validation-observer ref="validationForm" #default="{ invalid }">
            <b-card class="cardForm">
              
              <div class="d-flex w-100">

                <validation-provider rules="required" class="w-100" >
                  <b-dropdown
                    :disabled="(optionsNets.length == 0)"
                    id="network"
                    :text="$t(selectTextDefault)"
                    :class="`w-md-75 w-100 dropdown-net ${selectTextDefault !== 'select' ? 'p-auto button-dropdown' : ''}`"
                    v-model="selectedSocial"
                    variant="outline"
                    menu-class="submenu"
                  >
                  <template #button-content>
                    <b-img :src="netIcon" class="iconSocial mr-1" v-if="netIcon" />
                    <span>{{$t(selectTextDefault)}}</span>
                  </template>
                    <b-dropdown-item
                      v-for="item in optionsNets"
                      :key="item.value.uuid"
                      @click="dropdownItemClick(item)"

                    >
                      <b-img  
                        :src="getIcon(item.value.network)"
                        class="iconDropdown"
                      ></b-img>
                      <span> {{ item.text }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </validation-provider>
              </div>
              <b-form>
                <validation-provider rules="required">
                  <b-form-input
                    :disabled="selectTextDefault == 'select'"
                    v-model="post_url"
                    type="url"
                    :placeholder="$t('link')"
                    required
                    class="my-2"
                  ></b-form-input>
                </validation-provider>
                <validation-provider rules="required">
                  <b-form-input
                    :disabled="selectTextDefault == 'select'"
                    v-model="brand"
                    type="text"
                    :placeholder="$t('brandName')"
                    required
                    class="my-2"
                  ></b-form-input>
                </validation-provider>

                <b-form-file
                  :disabled="selectTextDefault == 'select'"
                  v-model="photo"
                  :placeholder="$t('photo')"
                  drop-placeholder="Drop file here..."
                  accept=".jpg, .png"
                  class="my-2"
                  :browse-text="$t('uploadFile')"
                ></b-form-file>

                <b-button
                  :disabled="invalid"
                  type="submit"
                  variant="primary"
                  @click.prevent="onSubmit"
                  >{{ $t("add") }}</b-button
                >
              </b-form>
            </b-card>
          </validation-observer>
        </b-col>

        <b-col
          cols="12"
          v-for="social in socialNetworks"
          :key="social.uuid"
          class="col-md-6 col-lg-4 col-xl-3"
        >
          <b-card
            :img-src="getImage(social)"
            img-alt="Image"
            img-top
            style="object-fit: cover"
            class="cardImage"
            ref="card"
          >
            <b-button class="buttonCard" variant="light" pill>
              <a :href="social.post_url" target="_blank">{{ substr(social.brand)  }}</a>
            </b-button>
            <div class="d-flex justify-content-between mt-n2">
              <b-button
                variant="none"
                class="deleteButton"
                @click="deleteBriefcase(social.uuid)"
              >
                <feather-icon icon="Trash2Icon" size="18" />
                {{ $t("delete") }}</b-button
              >
              <a :href="social.post_url" target="_blank">
                <b-img :src="getIcon(social.network)" class="iconSocialCard" />
              </a>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import others from "@/services/others";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import utils from "@/libs/utils"

import {
  BCard,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BForm,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BFormFile,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      utils,
      selectTextDefault: "select",
      required,
      options: [
        { value: "entertainment", label: "entertainment" },
        { value: "how_to_style", label: "how_to_style" },
        { value: "movies_and_tv", label: "movies_and_tv" },
        { value: "news_politics", label: "news_politics" },
        { value: "nonprofits_activism", label: "nonprofits_activism" },
        { value: "people_blogs", label: "people_blogs" },
        { value: "pets", label: "pets" },
        { value: "sports", label: "sports" },
        { value: "technology_science", label: "technology_science" },
        { value: "travel_tourism", label: "travel_tourism" },
        { value: "video_games", label: "video_games" },
        { value: "accesories_jewelry", label: "accesories_jewelry" },
        { value: "adult_content", label: "adult_content" },
        { value: "alcohol", label: "alcohol" },
        { value: "animals", label: "animals" },
        {
          value: "architecture_urban_design",
          label: "architecture_urban_design",
        },
        { value: "art_artists", label: "art_artists" },
        { value: "beauty", label: "beauty" },
        { value: "business_careers", label: "business_careers" },
        { value: "cars_motorbikes", label: "cars_motorbikes" },
        { value: "cinema_actors_actresses", label: "cinema_actors_actresses" },
        { value: "clothing_outfits", label: "clothing_outfits" },
        { value: "comics_sketches", label: "comics_sketches" },
        { value: "computers_gadgets", label: "computers_gadgets" },
        { value: "diy_design", label: "diy_design" },
        { value: "education", label: "education" },
        {
          value: "extreme_sports_outdoor_activity",
          label: "extreme_sports_outdoor_activity",
        },
        { value: "family", label: "family" },
        { value: "fashion", label: "fashion" },
        { value: "finance_economics", label: "finance_economics" },
        { value: "fitness_gym", label: "fitness_gym" },
        { value: "food_cooking", label: "food_cooking" },
        { value: "health_medicine", label: "health_medicine" },
        { value: "humor_fun_happiness", label: "humor_fun_happiness" },
        { value: "kids_toys", label: "kids_toys" },
        { value: "lifestyle", label: "lifestyle" },
        { value: "literature_journalism", label: "literature_journalism" },
        { value: "luxury", label: "luxury" },
        { value: "machinery_technologies", label: "machinery_technologies" },
        { value: "management_marketing", label: "management_marketing" },
        { value: "mobile_related", label: "mobile_related" },
        { value: "modeling", label: "modeling" },
        { value: "music", label: "music" },
        { value: "nature_landscapes", label: "nature_landscapes" },
        { value: "photography", label: "photography" },
        { value: "racing_sports", label: "racing_sports" },
        { value: "science", label: "science" },
        { value: "shopping_retail", label: "shopping_retail" },
        { value: "shows", label: "shows" },
        { value: "sports_with_ball", label: "sports_with_ball" },
        { value: "sweets_bakery", label: "sweets_bakery" },
        { value: "tobacco_smoking", label: "tobacco_smoking" },
        { value: "trainers_coaches", label: "trainers_coaches" },
        { value: "travel", label: "travel" },
        { value: "water_sports", label: "water_sports" },
        { value: "winter_sports", label: "winter_sports" },
        { value: "other", label: "other" },
      ],
      value: [],
      valueSelected: [],
      socialNetworks: [],
      netIcons: {
        tiktok: require("@/assets/images/icons/tiktok2.svg"),
        spotify: require("@/assets/images/icons/spotify2.svg"),
        pinterest: require("@/assets/images/icons/pinterest2.svg"),
        blog: require("@/assets/images/icons/blog2.svg"),
        snapchat: require("@/assets/images/icons/snapchat2.svg"),
        linkedin: require("@/assets/images/icons/linkedin2.svg"),
        youtube: require("@/assets/images/icons/youtube2.svg"),
        instagram: require("@/assets/images/icons/instagram2.svg"),
        twitter: require("@/assets/images/icons/twitter2.svg"),
        twitch: require("@/assets/images/icons/twitch2.svg"),
        facebook: require("@/assets/images/icons/facebook2.svg"),
      },
      backgroundDefault: require("@/assets/images/dashboard/imgDefault.png"),
      optionsNets: [],
      netIcon: "",
      selectedSocial: null,
      post_url: "",
      brand: null,
      photo: null,
      loaded: false,
    };
  },
  watch: {
    selectedSocial(valueSelected) {
      if (valueSelected.network === "tiktok")
        this.netIcon = this.netIcons.tiktok;
      else if (valueSelected.network === "spotify")
        this.netIcon = this.netIcons.spotify;
      else if (valueSelected.network === "pinterest")
        this.netIcon = this.netIcons.pinterest;
      else if (valueSelected.network === "blog")
        this.netIcon = this.netIcons.blog;
      else if (valueSelected.network === "snapchat")
        this.netIcon = this.netIcons.snapchat;
      else if (valueSelected.network === "linkedin")
        this.netIcon = this.netIcons.linkedin;
      else if (valueSelected.network === "youtube")
        this.netIcon = this.netIcons.youtube;
      else if (valueSelected.network === "instagram")
        this.netIcon = this.netIcons.instagram;
      else if (valueSelected.network === "twitter")
        this.netIcon = this.netIcons.twitter;
      else if (valueSelected.network === "twitch")
        this.netIcon = this.netIcons.twitch;
      else if (valueSelected.network === "facebook")
        this.netIcon = this.netIcons.facebook;
    },
  },

  computed: {
    availableOptions() {
      return this.options;
    },
  },
  beforeCreate() {
    const arr = [];
    const arr2 = [];
    others.getProfile().then((response) => {
      response.interests.map((val) => {
        const b = { value: val.interest, uuid: val.uuid };
        const a = { value: val.interest, label: val.interest };
        arr.push(a);
        arr2.push(b);
      });
      this.value = arr;
      this.valueSelected = arr2;
    });

    others.getOwnBriefcase().then((response) => {
      this.socialNetworks = response.results;
    });

    others.getSocialNetworks().then((response) => {
      response.results.filter((valu) => {
        const obj = {
          value: { uuid: valu.uuid, network: valu.network },
          text:  `${valu.username}`,
        };
        this.optionsNets.push(obj);
      });
      this.loaded = true;
    });
  },

  methods: {
    dropdownItemClick(net) {
      this.selectedSocial = net.value
      this.selectTextDefault = net.text
    },

    handleImgError() {
      return this.test
    },
    getImage(content) {
      if (content.preview_url) return content.preview_url
      return this.backgroundDefault
    },
    selected(optionSelected) {
      const form = {
        interest: optionSelected[optionSelected.length - 1].value,
      };
      others.addInterests(form).then((response) => {
        const b = { value: response.interest, uuid: response.uuid };
        this.valueSelected.push(b);
      });
    },
    deselected(optionDeselected) {
      this.valueSelected.map((val) => {
        if (val.value === optionDeselected.value) {
          others.removeInterests(val.uuid).then(() => {});
        }
      });
    },
    search(options, search) {
      const vue = this;
      const results = options.filter(function (option) {
        const translate = vue
          .$t(`mediaKits.${option.label}`)
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (
          translate.indexOf(
            search
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) !== -1
        ) {
          return option;
        }
      });
      return results;
    },

    onSubmit() {
      if (this.isValidURL(this.post_url) === false) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t('err'),
            text:  this.$t('urlError'),
            icon: "CoffeeIcon",
            variant: "danger",
          },
        });
      }

      this.$refs.validationForm.validate().then((valid) => {
        if (valid && this.isValidURL(this.post_url)) {
          const data = new FormData();
          if (this.photo) data.append("source_media", this.photo);

          data.append("network", this.selectedSocial.network);
          data.append("post_url", this.post_url);
          data.append("brand", this.brand);
          data.append("related_object", this.selectedSocial.uuid);

          others
            .addBriefcase(data)
            .then((response) => {
              if (response.status && response.status !== 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:  this.$t('err'),
                    text: 'Ocurrió un error al guardar la información',
                    icon: "CoffeeIcon",
                    variant: "danger",
                  },
                });

                return;
              }
              this.onReset();

              this.socialNetworks.push(response);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('saved'),
                  icon: "AlertIcon",
                  variant: "success",
                  text: this.$t('savedSuccess'),
                },
              });
            })
            .cacth((err) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title:  this.$t('err'),
                  text: err,
                  icon: "CoffeeIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    deleteBriefcase(uuid) {
      others.removeBriefcase(uuid).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:  this.$t('deleted'),
            icon: false,
            variant: "success",
            text:  this.$t('deletedSuccess'),
          },
        });
        const rest = this.socialNetworks.filter((f) => f.uuid !== uuid);
        this.socialNetworks = rest;
      });
    },
    getIcon(text) {
      for (const property in this.netIcons) {
        if (property === text) return this.netIcons[property];
      }
    },
    onReset() {
      this.selectTextDefault = 'select';
      this.selectedSocial = null;
      this.brand = "";
      (this.photo = null), (this.post_url = "");
      this.netIcon = ""
    },
    substr(s) {
      return s.substr(0, 20);
    },

    isValidURL(url) {
      const RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

      if (RegExp.test(url)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.message-social-add{
    padding: 20px;
    text-align: justify;
    display: block;
    font-size: medium;
  }
.dropdown-net{
  border: 1px solid #D8D6DE;
}
.dropdown-net:focus-within{
  border: none;
  border: 1px solid #7367F0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  
}
.cardForm {
  height: 366px;
}
.iconSocial {
  width: 28px;
}
.select {
  display: block;
  width: 100% !important;
}
.cardImage img {
  width: 100%;
  object-fit: cover;
  height: 300px;
}
.buttonCard {
  margin-top: -150px;
  margin-left: 10px;
}
.deleteButton {
  padding: 0px !important;
  color: red;
}
.deleteButton:hover {
  color: rgb(165, 4, 4);
}
.iconSocialCard {
  width: 25px !important;
  height: auto !important;
}
.iconSocialCard:hover {
  transition: all 300ms;
  transform: scale(1.3);
  height: auto !important;
}
</style>