<template>
  <b-card>
    <validation-observer #default="{invalid}">
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label-for="is_national"
              :description="$t('accountMx')"
            >
              <b-form-checkbox id="is_nacional" v-model="is_national">{{$t('accountNational')}}</b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('nameBeneficiary')"
              label-for="beneficiary_name"
            >
              <validation-provider rules="required">
                <b-form-input id="beneficiary_name" v-model="beneficiary_name"/>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('lastNameBeneficiary')"
              label-for="beneficiary_last_name"
            >
              <validation-provider rules="required">
                <b-form-input id="beneficiary_last_name" v-model="beneficiary_last_name"/>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('bank')"
              label-for="bank"
            >
              <validation-provider rules="required">
                <b-form-input id="bank" v-model="bank"/>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="getLabelClabe()"
              label-for="clabe"
            >
              <validation-provider rules="required">
                <b-form-input id="clabe" v-model="clabe"/>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('account')"
              label-for="account"
            >
              <validation-provider rules="required">
                <b-form-input id="account" v-model="account"/>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('email')"
              label-for="email"
            >
              <validation-provider rules="required|email" name="email" #default="{ errors }">
                <b-form-input id="email" v-model="email_value"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group :label="$t('telephone')">
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required"
              >
                <vue-phone-number-input
                  @update="countryPhone"
                  :preferred-countries="['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US']"
                  :show-code-on-list="true"
                  v-model="telephone"
                  :translations="{
                    countrySelectorLabel: $t('countryLabel'),
                    countrySelectorError: $t('countryError'),
                    phoneNumberLabel: $t('telephone')}"
                  default-country-code="MX"
                  :required="true"
                  :no-example="true"
                  :no-validator-state="true"
                  :error="errorPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              v-if="!is_national"
              :label="$t('city')"
              label-for="city"
            >
              <validation-provider rules="required">
                <b-form-input id="city" v-model="city"/>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              v-if="!is_national"
              :label="$t('address')"
              label-for="address"
            >
              <validation-provider rules="required">
                <b-form-input id="address" v-model="address"/>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-1 text-center">
        <b-button
          variant="outline-success"
          :disabled="invalid || errorPhone"
          @click="updatePyamentData()"
        >
          {{$t('dashboard.save')}}
        </b-button>
      </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormCheckbox, BButton, BFormInput,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import utils from "@/libs/utils";
import profile_service from "@/services/profile";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: "PaymentData",
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
  },
  data() {
    return {
      required,
      email,
      is_national: false,
      beneficiary_name: '',
      beneficiary_last_name: '',
      bank: '',
      clabe: '',
      account: '',
      email_value: '',
      telephone: '',
      errorPhone: false,
      country: '',
      city: '',
      address: ''
    };
  },
  created() {
    const user = utils.getUserData()
    if (user.has_payment_data) {
      this.is_national = user.payment_data.is_national
      this.beneficiary_name = user.payment_data.beneficiary_name
      this.beneficiary_last_name = user.payment_data.beneficiary_last_name
      this.bank = user.payment_data.bank
      this.clabe = user.payment_data.clabe
      this.account = user.payment_data.account
      this.email_value = user.payment_data.email
      this.telephone = user.payment_data.telephone
      this.errorPhone = user.payment_data.errorPhone
      this.country = user.payment_data.country
      this.city = user.payment_data.city
      this.address = user.payment_data.address
    }
  },
  methods: {
    getLabelClabe() {
      return this.is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'
    },
    formatNumber(number) {
      const string = number.replace(/ /g,'')
      return `${string.substring(0, 3)} ${string.substring(3, 6)} ${string.substring(6, string.length)}`;
    },
    countryPhone (data) {
      this.country = data.countryCode
      if (data.phoneNumber) {
        this.telephone = this.formated_phone = this.formatNumber(data.phoneNumber)
      } else {
        this.telephone = this.formated_phone = ''
      }
      this.validPhone()
    },
    validPhone () {
      this.errorPhone = this.formated_phone.length > 12 || this.formated_phone.length < 11
    },
    updatePyamentData() {
      const form_data = {
        is_national: this.is_national,
        beneficiary_name: this.beneficiary_name,
        beneficiary_last_name: this.beneficiary_last_name,
        bank: this.bank,
        clabe: this.clabe,
        account: this.account,
        email: this.email_value,
        telephone: this.telephone,
        country: this.country,
        city: !this.is_national ? this.city : null,
        address: !this.is_national ? this.address : null,
        username: this.$route.params.username,
        network: this.$route.params.network,
      }
      profile_service.updatePaymentData(form_data)
        .then(response => {
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:  this.$t('err'),
                text: this.$t('messageAccountSettingError'),
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('dashboard.saved'),
                variant: 'success',
                icon: null,
                text: ''
              }
            })
            utils.updatePaymentData(response)
          }
        })
    }
  },
};
</script>
<style>
</style>