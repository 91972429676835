var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"updateProfile",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-username"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('validation-provider',{attrs:{"name":_vm.$t('name'),"vid":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nombre","name":"Nombre","state":errors.length > 0 ? false:null},model:{value:(_vm.optionsLocal.first_name),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "first_name", $$v)},expression:"optionsLocal.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"apellido"}},[_vm._v(_vm._s(_vm.$t('lastname')))]),_c('validation-provider',{attrs:{"name":_vm.$t('lastname'),"vid":_vm.$t('lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"apellido","placeholder":"Apellido","state":errors.length > 0 ? false:null},model:{value:(_vm.optionsLocal.last_name),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "last_name", $$v)},expression:"optionsLocal.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"gender"}},[_vm._v(_vm._s(_vm.$t('gender')))]),_c('validation-provider',{attrs:{"name":_vm.$t('gender'),"vid":_vm.$t('gender'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:{'is-invalid': !!errors.length},attrs:{"id":"gender","name":"gender","state":errors.length > 0 ? false:null},model:{value:(_vm.optionsLocal.gender),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "gender", $$v)},expression:"optionsLocal.gender"}},_vm._l((_vm.genders),function(gender){return _c('b-form-select-option',{key:gender.value,attrs:{"value":gender.value}},[_vm._v(_vm._s(_vm.$t("genders."+gender.text)))])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.optionsLocal.main_group.name === 'brand')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"position"}},[_vm._v(_vm._s(_vm.$t('position')))]),_c('validation-provider',{attrs:{"name":_vm.$t('position'),"vid":_vm.$t('position'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:{'is-invalid': !!errors.length},attrs:{"id":"position","name":"position","state":errors.length > 0 ? false:null,"options":_vm.positions},model:{value:(_vm.optionsLocal.position),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "position", $$v)},expression:"optionsLocal.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.optionsLocal.main_group.name === 'creator')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"question_text"}},[_vm._v(_vm._s(_vm.$t('question_text')))]),_c('validation-provider',{attrs:{"name":_vm.$t('question_text'),"vid":_vm.$t('question_text'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:{'is-invalid': !!errors.length},attrs:{"id":"question_text","name":"question_text","state":errors.length > 0 ? false:null},model:{value:(_vm.optionsLocal.question_text),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "question_text", $$v)},expression:"optionsLocal.question_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('validation-provider',{attrs:{"name":_vm.$t('email'),"vid":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","placeholder":"Email","state":errors.length > 0 ? false:null,"disabled":""},model:{value:(_vm.optionsLocal.email),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "email", $$v)},expression:"optionsLocal.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"telephone"}},[_vm._v(_vm._s(_vm.$t('telephone')))]),_c('validation-provider',{ref:"phoneNumber",attrs:{"name":_vm.$t('telephone'),"vid":_vm.$t('telephone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"preferred-countries":['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US'],"show-code-on-list":true,"translations":{
                countrySelectorLabel: _vm.$t('countryLabel'),
                countrySelectorError: _vm.$t('countryError'),
                phoneNumberLabel: _vm.$t('telephone')},"default-country-code":_vm.optionsLocal.country,"required":true,"no-validator-state":true,"no-example":true,"error":_vm.errorPhone},on:{"update":_vm.countryPhone},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('telephone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"height":"0","width":"0","overflow":"hidden","padding":"0","border":"0"},attrs:{"placeholder":"Enter your name"},model:{value:(_vm.valid_phone),callback:function ($$v) {_vm.valid_phone=$$v},expression:"valid_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"question_text"}},[_vm._v(_vm._s(_vm.$t('dashboard.changeAvatar')))]),_c('b-form-file',{staticClass:"form-file-avatar",attrs:{"size":"lg","placeholder":_vm.$t('dashboard.chooseFile'),"drop-placeholder":"$t('dashboard.dropFile')","accept":".jpg, .png","browse-text":_vm.$t('uploadFile')},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"disabled":invalid || _vm.errorPhone,"variant":"primary"},on:{"click":_vm.updateProfile}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }