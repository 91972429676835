<template>
  <div>
    <b-card-title>{{ $t("offer") }}</b-card-title>
    <b-card-text>{{ $t("saveMain") }}</b-card-text>

    <b-row>
      <b-col cols="12">
        <b-card>
          <div v-if="optionsNets.length === 0 && loaded" class="alert alert-warning message-social-add" role="alert">
            <div>
              <feather-icon icon="InfoIcon" class="mr-1" size="20"/>
                {{ $t("alertDisconnected") }}</div> 
              <div class="size-text-msj"> 
                {{ $t("alertDisconnectedSecondary") }}
              </div>
          </div>
        <validation-observer ref="validationForm" #default="{ invalid }">

          <b-form>
            <div>
              <b-row class="d-flex  align-items-center flex-wrap ">
                <div class="col-12 col-md-6 col-lg-6  col-xl-6">
                  <validation-provider rules="required">
                    <b-dropdown
                      :disabled="(optionsNets.length == 0)"
                      id="network"
                      :class="`w-md-75 w-100 dropdown-net ${selectTextDefault !== 'select' ? 'p-auto button-dropdown' : ''}`"
                      :text="$t(select_text_default)"
                      v-model="selectedSocialPay"
                      variant="outline"
                      menu-class="submenu"
                    >
                      <template #button-content>
                          <b-img 
                            :src="utils.getDropdownNetworks().find((item) => item.value === selected_network.toLowerCase()).icon" 
                            class="dropdown-icon-pricing"
                            v-if="select_text_default !== 'default_select_net'"/>
                          <span class="text-overhidden">{{$t(select_text_default)}}</span>
                      </template>
                      <b-dropdown-item
                        v-for="item in optionsNets"
                        :key="item.value.uuid"
                        @click="dropdownItemClick(item)"
                      >
                        <b-img  
                          :src="getIcon(item.value.network)"
                          class="iconDropdown"
                        ></b-img>
                        <span> {{ item.text }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </validation-provider>
                </div>
                <div class="col-12 mt-1 mt-md-0 col-md-6 col-lg-6 col-xl-6">
                  <validation-provider rules="required">
                    <b-form-select 
                      :disabled="select_text_default == 'default_select_net'"
                      class=" w-100"
                      v-model="content_type"
                      required
                      :key="changed_select"
                    >
                      <b-form-select-option v-for="item in getTypeContent(selected_network)" :key="item.value" :value="item.value"> {{$t(item.label)}}</b-form-select-option>
                    </b-form-select>
                   </validation-provider>
                </div>

                <div class="col-12 mt-1 mt-lg-0 col-lg-6 col-xl-6">
                  <validation-provider>
                      <b-form-input
                      :disabled="select_text_default == 'default_select_net'"
                      class="w-100 "
                      v-model="details"
                      :placeholder="$t('details')"
                      maxlength="50"
                      ></b-form-input>

                  </validation-provider>
               </div>


                <div class="d-flex  col-12 justify-content-center justify-content-lg-end  mt-2 flex-wrap col-lg-6 col-xl-6">
                  <b-card-title class="mt-1 mr-1">{{$t('cost')}} $</b-card-title>
                  <validation-provider rules="required" class="w-25">
                    <b-form-input
                      :disabled="select_text_default == 'default_select_net'"
                      type="number"
                      v-model="price"
                      placeholder="0"
                      required
                    ></b-form-input>
                  </validation-provider>
                  <b-button
                    variant="none"
                    type="submit"
                    class="buttonAdd mb-1 p-0 pl-1"
                    @click.prevent="addPricing"
                    :disabled="invalid"
                    >+ {{ $t("add") }}</b-button
                  >
                </div>
              </b-row>

            </div>
          </b-form>
        </validation-observer>
        </b-card>

      </b-col>
    </b-row>

    <b-row>
        <b-col cols="12"  class="col-md-4 col-lg-4 col-xl-3 col-sm-6" v-for="item in pricingList" :key="item.uuid">
            <b-card class="cardPricing">

                <feather-icon size="20" icon="XIcon" class="xIcon float-right" @click="deletePrice(item.uuid)"/>

              <div class="d-flex flex-wrap align-content-between h-100">
                <b-card-title class="w-100">    
                  <b-img :src="getIcon(item.network)" class="iconDropdown iconList "/> {{$t(item.content_type)}}
                </b-card-title>
      
                <b-card-text class="w-100" v-if="item.description">
                  <b-button hover :title="item.description" variant="none" class="p-0 w-100 text-justify">
                    {{substr(item.description)}} ...
                  </b-button>
                </b-card-text>

                <b-card-text class="w-100">
                  $ {{mexicoFormat(item.price)}}
                </b-card-text>
              </div>
            </b-card>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import others from "@/services/others";
import { ValidationProvider,ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import utils from '@/libs/utils';

import {
  BCard,
  BButton,
  BCardText,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BImg,
  BRow,
  BCol,
  BCardTitle,
  BForm,
  BFormSelectOption,
  // BAvatar,
  // BTooltip,
} from "bootstrap-vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BCard,
    BButton,
    BCardText,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BRow,
    BCol,
    BCardTitle,
    BForm,
    BFormSelectOption,
  },

  data() {
    return {
      utils,
      required,
      dropdownNetworks: [
        {
          value: "tiktok",
          network: "Tiktok",
          icon: require("@/assets/images/icons/tiktok2.svg"),
        },
        {
          value: "spotify",
          network: "Spotify",
          icon: require("@/assets/images/icons/spotify2.svg"),
        },
        {
          value: "snapchat",
          network: "Snapchat",
          icon: require("@/assets/images/icons/snapchat2.svg"),
        },
        {
          value: "blog",
          network: "Blog",
          icon: require("@/assets/images/icons/blog2.svg"),
        },
        {
          value: "pinterest",
          network: "Pinterest",
          icon: require("@/assets/images/icons/pinterest2.svg"),
        },

        {
          value: "linkedin",
          network: "LinkedIn",
          icon: require("@/assets/images/icons/linkedin2.svg"),
        },
        {
          value: "youtube",
          network: "Youtube",
          icon: require("@/assets/images/icons/youtube2.svg"),
        },
        {
          value: "instagram",
          network: "Instagram",
          icon: require("@/assets/images/icons/instagram2.svg"),
        },
        {
          value: "twitter",
          network: "Twitter",
          icon: require("@/assets/images/icons/twitter2.svg"),
        },
        {
          value: "twitch",
          network: "Twitch",
          icon: require("@/assets/images/icons/twitch2.svg"),
        },
        {
          value: "facebook",
          network: "Facebook",
          icon: require("@/assets/images/icons/facebook2.svg"),
        },
      ],
      optionsTypeContent: [
        { value: "photo", text: "photo" },
        { value: "video", text: "video" },
        { value: "post", text: "post" },
        { value: "story", text: "story" },
        { value: "instagram_tv", text: "instagram_tv" },
        { value: "snap", text: "snap" },
        { value: "streaming", text: "streaming" },
        { value: "tweet", text: "tweet" },
        { value: "reel", text: "reel" },
      ],
      type_contents : [
        {
          value: null,
          type: [{ value: null, label: "Selecciona una red primero" }],
        },
        {
          value: "instagram",
          type: [
            { value: "story", label: "Story" },
            { value: "photo", label: "Foto" },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "youtube",
          type: [
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
            { value: "reel", label: "Short" },
          ],
        },
        {
          value: "tiktok",
          type: [
            { value: "reel", label: "TikTok" },
            { value: "story", label: "Story" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "facebook",
          type: [
            { value: "post", label: "Post" },
            { value: "photo", label: "Foto" },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "twitter",
          type: [
            { value: "post", label: "Post" },
            { value: "photo", label: "Foto" },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "linkedin",
          type: [
            { value: "post", label: "Post" },
            { value: "photo", label: "Foto" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "spotify",
          type: [{ value: "podcast", label: "Podcast" }],
        },
        {
          value: "twitch",
          type: [
            { value: "streaming", label: "Live stream" },
            { value: "clip", label: "Clip" }
          ],
        },
        {
          value: "pinterest",
          type: [
            { value: "photo", label: "Foto" },
            { value: "video", label: "Video" },
          ],
        },
        {
          value: "blog",
          type: [{ value: "post", label: "Blog Post" }],
        },
        {
          value: "snapchat",
          type: [
            { value: "story", label: "Story" },
            { value: "reel", label: "Spotlight" },
          ],
        },
      ],
      content_type: null,
      selectedSocialPay: null,
      select_text_default: "default_select_net",
      selected_network: null,
      optionsNets: [],
      price: "",
      details: "",
      pricingList: [],
      changed_select: false,
      loaded: false,
    };
  },

  beforeCreate() {
    others.getSocialNetworks().then((response) => {
      response.results.filter((valu) => {
        const obj = {
          value: { uuid: valu.uuid, network: valu.network },
          text: `${valu.username}`,
        };
        this.optionsNets.push(obj);
      });
      this.loaded = true;
    });

    others.getOwnPricing().then((response) => {
      this.pricingList = response.results
    })

  },

  methods: {
    getTypeContent(network) {
      return this.type_contents.filter((item) => item.value === network)[0].type;
    },
    addPricing() {
    
      const data = new FormData();
      data.append('related_object', this.selectedSocialPay);
      data.append('content_type',this.content_type);
      data.append('price',this.price);
      data.append('description',this.details);
      others.addPricing(data).then((response) => {
        if (!response.status) {
          this.pricingList.push(response)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('saved'),
              icon: "AlertIcon",
              variant: "success",
              text: this.$t('savedSuccess'),
            },
          });          
          this.onReset()
        } else { 
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:  this.$t('err'),
              text: 'Error al guardar',
              icon: "CoffeeIcon",
              variant: "danger",
            },
          });
        }
      })
    },

    dropdownItemClick(net) {
      this.selected_network = net.value.network;
      this.selectedSocialPay = net.value.uuid;
      this.select_text_default = net.text;
      this.content_type = this.getTypeContent(this.selected_network)[0].value;
    },
    getIcon(text) {
      let icon = "";
      this.dropdownNetworks.map((a) => {
        if (a.value === text) icon = a.icon;
      });
      return icon;
    },
    onReset() {
      this.price = "";
      this.details = "";
      this.content_type = null;
      this.changed_select = !this.changed_select;
      this.selected_network = null;
      this.select_text_default = "default_select_net";
    },
    substr(s) {
      return s.substr(0, 25);
    },

    mexicoFormat(number) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      return number.toString().replace(exp,rep);
    },
    deletePrice(uuid) {
      others.deletePricing(uuid).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:  this.$t('deleted'),
            icon: 'AlertIcon',
            variant: "success",
            text:  this.$t('deletedSuccess'),
          },
        });
      })
      
      const rest = this.pricingList.filter((f) => f.uuid !== uuid);
      this.pricingList = rest;
    }
  },
};
</script>

<style>
.message-social-add{
    padding: 20px;
    text-align: justify;
    display: block;
    font-size: medium;
  }
.dropdown-net{
  border: 1px solid #D8D6DE;
}
.dropdown-net:focus-within{
  border: none;
  border: 1px solid #7367F0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  
}
.buttonAdd {
  color: green !important;
}
.iconList{
  width: 30px;
}
.xIcon{
  color: #979A9A;
  cursor: pointer;
}
.cardPricing{
  height: 180px;
}
.dropdown-icon-pricing {
  width: 24px;
  margin: 0 0.2em 0 0;
}
@media(max-width:1358px){
  .cardPricing{
  height: 235px;
}
}

@media(max-width:1199px){
  .cardPricing{
  height: 180px;
  }
}

</style>