<template>
  <b-card>

    <!-- form -->
    <validation-observer
      ref="updateProfile"
      #default="{invalid}"
    >
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('name')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('name')"
                :vid="$t('name')"
                rules="required">
                <b-form-input
                  v-model="optionsLocal.first_name"
                  placeholder="Nombre"
                  name="Nombre"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label for="apellido">{{$t('lastname')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('lastname')"
                :vid="$t('lastname')"
                rules="required">
                <b-form-input
                  v-model="optionsLocal.last_name"
                  name="apellido"
                  placeholder="Apellido"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label for="gender">{{$t('gender')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('gender')"
                :vid="$t('gender')"
                tag="div"
              >
                <b-form-select
                  id="gender"
                  v-model="optionsLocal.gender"
                  name="gender"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-select-option :value="gender.value" v-for="gender in genders" :key="gender.value">{{ $t("genders."+gender.text)}}</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="optionsLocal.main_group.name === 'brand'">
            <b-form-group>
              <label for="position">{{$t('position')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('position')"
                :vid="$t('position')"
                tag="div"
              >
                <b-form-select
                  id="position"
                  v-model="optionsLocal.position"
                  name="position"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                  :options="positions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="optionsLocal.main_group.name === 'creator'">
            <b-form-group>
              <label for="question_text">{{$t('question_text')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('question_text')"
                :vid="$t('question_text')"
                tag="div"
              >
                <b-form-textarea
                  id="question_text"
                  v-model="optionsLocal.question_text"
                  name="question_text"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label for="email">{{$t('email')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('email')"
                :vid="$t('email')">
                <b-form-input
                  id="email"
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false:null"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label for="telephone">{{$t('telephone')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('telephone')"
                :vid="$t('telephone')"
                ref="phoneNumber"
                rules="required"
              >
                <VuePhoneNumberInput
                  @update="countryPhone"
                  v-model="telephone"
                  :preferred-countries="['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US']"
                  :show-code-on-list="true"
                  :translations="{
                  countrySelectorLabel: $t('countryLabel'),
                  countrySelectorError: $t('countryError'),
                  phoneNumberLabel: $t('telephone')}"
                  :default-country-code="optionsLocal.country"
                  :required="true"
                  :no-validator-state="true"
                  :no-example="true"
                  :error="errorPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('telephone')"
              >
                <b-form-input v-model="valid_phone" placeholder="Enter your name" style="height:0;width:0;overflow:hidden;padding: 0;border: 0;"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="question_text">{{$t('dashboard.changeAvatar')}}</label>
                <b-form-file
                  class="form-file-avatar"
                  size="lg"
                  v-model="avatar"
                  :placeholder="$t('dashboard.chooseFile')"
                  drop-placeholder="$t('dashboard.dropFile')"
                  accept=".jpg, .png"
                  :browse-text="$t('uploadFile')"
                ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              :disabled="invalid || errorPhone"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateProfile"
            >
              {{$t('save')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{$t('reset')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BCard, BFormSelect ,BFormSelectOption, BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import service from '@/services/others'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import profile_services from '@/services/settings';
import abilities from '@/libs/acl/abilities';
import utils from '@/libs/utils';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BFormSelectOption,
    VuePhoneNumberInput,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  directives: {
    Ripple
  },
  props: {
    optionsLocal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      avatar: null,
      positions: this.$t('positions'),
      required,
      length,
      errorPhone: false,
      telephone: '',
      valid_phone: '',
      genders: [
        {"value": "", "text": "selectGender"},
        {"value": "male", "text": "male"},
        {"value": "female", "text": "female"},
        {"value": "no_binary", "text":"noBinary"},
        {"value": "other", "text":"other"}
      ],
    }
  },
  created () {
    localize(this.$i18n.locale)
    this.telephone = this.optionsLocal.telephone
    this.optionsLocal.gender = this.optionsLocal.gender ? this.optionsLocal.gender.toLowerCase() : '';
  },
  methods: {
    changeAvatar() {  
      if (this.avatar) {
        profile_services.changeAvatar(this.avatar)
          .then(result => {
            this.avatar = null;
            const readAbility = abilities.getAbilitie(result.user)
            utils.setuserData(result, readAbility);
            this.$root.$emit('profile_update')
            this.profile_image = this.getImgProfile(result.user.profile_image);
          }).catch(() => {
          });
      }
    },
    resetForm () {
      this.getProfile()
    },
    formatNumber(number) {
      const string = number.replace(/ /g,'')
      return `${string.substring(0, 3)} ${string.substring(3, 6)} ${string.substring(6, string.length)}`;
    },
    countryPhone (data) {
      this.optionsLocal.country = data.countryCode
      if (data.phoneNumber) {
        const format_number = this.formatNumber(data.phoneNumber);
        this.telephone = format_number
        this.valid_phone = format_number 
      } else {
        this.telephone = this.valid_phone = ''
      }
      this.validPhone()
    },
    getProfile () {
      service.getProfile()
        .then(response => {
          this.optionsLocal = response.user
        })
    },
    updateProfile () {
      this.optionsLocal.telephone = this.telephone;
      service.updateProfile(this.optionsLocal)
        .then(response => {
          this.optionsLocal = response.user;
          this.changeAvatar();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('dataUpdated'),
              icon: 'CoffeeIcon',
              variant: 'success',
              text:  this.$t('dataSuccess')
            }
          })
        }).catch(error => {

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:  this.$t('dataError'),
              text: this.getError(error.response.data.response),
              icon: 'CoffeeIcon',
              variant: 'danger',
            }
          })
        })
    },
    getError(error) {
      if (error.first_name) return  this.$t('dataErrorname')
      if (error.last_name) return  this.$t('dataErrorlastname')
      if (error.gender) return 'Género es necesario'
    },
    validPhone () {
      this.errorPhone = this.telephone.length > 12 || this.telephone.length < 11
    }
  },
  setup () {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>
<style>
.country-selector__list__item {
  display: block;
  padding: 2em 1em;
}
.form-file-avatar {
  z-index: 0;
}
</style>