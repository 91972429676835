<template>
  <b-card>
    <b-row>
    <card-membership></card-membership>
    <card-membership></card-membership>
  </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
} from 'bootstrap-vue'

import cardMembership from '@/views/pages/creator/cardMembership.vue'

export default {
  components: {
    BCard,
    BRow,
    cardMembership
  },

  created () {
  },
  
}
</script>