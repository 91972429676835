<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="updatePassword" #default="{invalid}">
      <b-form @submit.prevent="changePass">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group>
              <label for="account-old-password">{{$t('oldPassword')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('oldPassword')"
                :vid="$t('oldPassword')"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="objPass.last_pass"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :class="{'is-invalid': !!errors.length}"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('oldPassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group>
              <label for="account-new-password">{{$t('newPassword')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('newPassword')"
                :vid="$t('newPassword')"

              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="objPass.new_pass"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('newPassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group>
              <label for="account-retype-new-password">{{$t('retypePassword')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('retypePassword')"
                :vid="$t('retypePassword')"
                :rules="`confirmed:${$t('newPassword')}`"

              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="objPass.new_pass_check"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('retypePassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              :disabled="invalid"
            >
              {{$t('save')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="resetForm()"
            >
              {{$t('reset')}}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import service from '@/services/others'
import { confirmed} from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      objPass: {
        last_pass: '',
        new_pass: '',
        new_pass_check: ''
      },
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      confirmed
    }
  },
  created () {
    localize('es')
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePass() {
      this.$refs.updatePassword.validate()
        .then(valid => {
          if (valid) {
            service.updatePassword(this.objPass)
              .then(response => {
                console.log(response)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title:  this.$t('passwordUpdated'),
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: this.$t('passwordSuccess')
                  }
                })
                this.resetForm()
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('passwordFailed'),
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: this.$t('passwordError')
                  }
                })
              })
          }
        })
    },
    resetForm() {
      this.objPass = {
        last_pass: '',
        new_pass: '',
        new_pass_check: ''
      }
    }
  },
}
</script>
