<template>
  <div>
    <b-card>
      <validation-observer ref="newNetForm" #default="{ invalid }">
        <b-form @submit.prevent="addNetwork">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center mb-2">
                <feather-icon icon="LinkIcon" size="18" />
                <h4 class="mb-0 ml-75">
                  {{ $t("addSocialNetwork") }}
                </h4>
              </div>
            </b-col>

            <!-- network -->
            <b-col class="col-12 col-md-6 col-lg-4 select-network">
              <b-form-group>

                <validation-provider
                  :name="$t('network')"
                  :vid="$t('network')"
                  rules="required"
                >

                  <b-dropdown
                    id="network"
                    :text="$t(selectTextDefault)"
                    :class="`w-md-75 w-100 dropdown-net ${selectTextDefault !== 'select' ? 'p-auto button-dropdown' : ''}`"
                    v-model="selected"
                    variant="outline"
                    menu-class="submenu"
                    no-flip
                  >
                    <template #button-content>
                      <b-img 
                        :src="utils.getDropdownNetworks().find((item) => item.value === selectTextDefault.toLowerCase()).icon" 
                        class="dropdown-icon-social-settings"
                        v-if="selectTextDefault !== 'select'"/>
                      <span>{{$t(selectTextDefault)}}</span>
                    </template>
                    <b-dropdown-item v-on:click="dropdownItemClick(item)" v-for="item in dropdownNetworks" :key="item.value">
                      <b-img :src="item.icon" class="iconDropdown"></b-img>
                      <span > {{ item.network }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ network -->

            <!-- username -->
            <b-col class="col-12 col-md-6 col-lg-5" v-if="selected">
              <b-form-group>
                <label for="username">{{ $t("username") }}</label>
                <validation-provider
                  name="$t('username')"
                  vid="$t('username')"
                  rules="required"
                >
                  <b-form-input autocomplete="off" id="username" v-model="newNetwork.username" />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ username -->
            <b-col class="p-2 col-12 col-lg-2">
              <b-button :disabled="invalid" type="submit" class="blue-button button-link" variant="blue-button">
               <b-icon icon="plus-circle"/>
               {{$t('settings.social.add')}}</b-button>
            </b-col>
            <b-col v-show="false" md="5" class="p-2">
              <v-facebook-login
                :app-id="facebook_app_id"
                v-model="user_face"
                :login-options="facebookOptions"
                :options="optionsButton"
                @login="getUserDataFacebook"
                @sdk-init="handleSdkInit"
              >
              </v-facebook-login>
            </b-col>
                        
            <b-col v-show="false" md="5" class="p-2">
              <v-facebook-login
                :app-id="facebook_business_app_id"
                v-model="insta_face"
                :login-options="instagramOptions"
                :options="optionsButton"
                @login="getUserDataInstagram"
                @sdk-init="handleSdkInitInstagram"
                :key="selected"
              >
              </v-facebook-login>
            </b-col>

            <b-col v-show="false" md="5" class="p-2">
              <b-button @click="youtubeLogin()" class="google-btn">
                <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google-ico.png')}) transparent 5px 50% no-repeat;`"></span>
                <span class="google-text">{{ $t('login.googleLogin') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row>
        <!-- Profile Social Networks -->
        <b-col cols="12" class="mt-1">
          <div class="d-flex align-items-center mb-3">
            <feather-icon icon="UserIcon" size="18" />
            <h4 class="mb-0 ml-75">
              {{ $t("aggregatedNetworks") }}
            </h4>
          </div>
          
          <div v-if="networks.length === 0 && loaded" class="alert alert-warning net-social-message" role="alert">
            <div>
              <feather-icon icon="InfoIcon" class="mr-1" size="20"/> {{ $t("msjRedSocial") }}
            </div>
          </div>
          <div v-if="netConnect() === false && networks.length != 0 " class="alert alert-warning social-message-disconnected" role="alert">
          <div>
            <feather-icon icon="InfoIcon" class="mr-1" size="20"/>
              {{ $t("alertDisconnected") }}</div> 
            <div class="size-text-msj"> 
              {{ $t("alertDisconnectedSecondary") }}
            </div>
          </div>

          <b-row class="text-center responsive-xs">
            <!-- profile -->
            <b-col
              v-for="(profile, x) in networks"
              :key="x"
              class="col-6 col-xl-3 col-lg-3"
            > 

              <div :class="`card size-card ${profile.connected ? utils.getBgNetwork(profile.network) : utils.getOutlineNetwork(profile.network)}`" >
                
                <div :class="`${ profile.connected ? 'icon-connected' : 'icon-disconnected'} ${utils.getClassNetworkSpan(profile.network)} `" @click="redirectNetwork(profile)"/>
                <div class="content-animation" v-if="!profile.connected">
                  <div><b-icon icon="plus-circle-fill"  class="icon-plus" v-if="checkNetworkConnect(profile.network)" @click="redirectNetwork(profile)"/></div>
                  <div><h1  class="text-plus">{{ $t("connectNetwork") }}</h1></div>
                </div>
                <div class="animation-connect">
                  
                  <b-button class="blue-button" variant="blue-button" :class="`${profile.connected ? 'size-check-connected mr-1' : 'd-none'}`" 
                    v-if="checkNetworkConnect(profile.network)" @click="checkToken(profile)"> {{$t('profile.checkPermisssions')}}
                  </b-button>
                   <b-button size="sm" v-if="profile.connected" class="size-x-connected justify-content-center" variant="danger" @click="deleteNetwork(profile)"><b-icon icon="trash"/></b-button> 

                  <div v-if="!profile.connected" class="size-x-disconnected">
                    <b-icon icon="trash"  @click="deleteNetwork(profile)"/>
                  </div>
                  <div :class="profile.connected ? 'container-check-icon' : 'container-icon-alert'"></div>
                  <div  :class="profile.connected ? 'check-icon' : 'icon-alert'">
                    <b-icon :icon="profile.connected ? 'check' : 'exclamation'"/>
                  </div>
                </div>
                <b-card-text :class="`${profile.connected ? 'network-name-connected' : 'network-name-disconnected'}`" @click="redirectNetwork(profile)">
                {{ capitalString(profile.network) }}
              </b-card-text>
              <b-card-text :class="`${profile.connected ? 'user-name-connected' : 'user-name-disconnected'}`">
                <b-link :class="`${profile.connected ? 'user-name-connected' : 'user-name-disconnected'}`"
                 v-if="profile.network === 'youtube'" @click="showDetailYoutube(profile)">
                  <div class="profile-username">@{{profile.readable_name}}</div>
                </b-link>
                
                <b-link :class="`${profile.connected ? 'user-name-connected' : 'user-name-disconnected'}`" v-else-if="profile.network === 'instagram' && profile.connected" @click="showMediaInstagram(profile)">
                  <div class="profile-username">@{{profile.username}}</div>
                </b-link>
                <b-link :class="`${profile.connected ? 'user-name-connected' : 'user-name-disconnected'}`" v-else :disabled="profile.network == 'linkedin' || profile.network == 'tiktok'" :href="getUrl(profile)" target="_blank">
                  <div class="profile-username">@{{profile.username}}</div>
                </b-link>
              </b-card-text>
              </div>
                
            </b-col>
            <!--/ profile -->
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <modal-videos-youtube v-if="videos_youtube.length > 0" :videos="videos_youtube" :modalShow="modal_videos" :key="change_modal_videos"/>
    <modal-media-instagram v-if="instagram_medias.length > 0" :medias="instagram_medias" :username="username_modal" :modalShow="modal_instagram" :key="change_modal_instagram"/>
    <consent-spotify :modalShow="modal_consent" :key="change_modal_consent" @loginSpotify="redirectSpotifyLogin"/>
    <modal-brandme :show_modal="show_modal_username" :key="change_modal_username">
      <template #title>
        <span>{{$t('titleConfirmUsername')}}</span>
      </template>
      <template #body>
        <p>{{$t('messageConfirmUsername')}} {{username_validate}}</p>
        <div class="d-flex justify-content-between">
          <b-button variant="outline-danger" @click="cancelConfirmUsername()">{{$t('campaigns.msjCancel')}}</b-button>
          <b-button variant="success" @click="confirmUsername()">{{$t('campaigns.confirmButton')}}</b-button>
        </div>
      </template>
    </modal-brandme>

    <modal-brandme :show_modal="show_modal_login" :key="`modal_login_${change_modal_login}`">
      <template #body>
        <div class="login-facebook">
        <v-facebook-login

          :app-id="facebook_app_id"
          v-model="user_face"
          :login-options="facebookOptions"
          :options="optionsButton"
          @login="getUserDataFacebook"
          @sdk-init="handleSdkInit"
          :key="network_to_link"
          v-if="network_to_link === 'facebook'"
        >
        </v-facebook-login>

        <v-facebook-login
          :app-id="facebook_business_app_id"
          v-model="insta_face"
          :login-options="instagramOptions"
          :options="optionsButton"
          @login="getUserDataInstagram"
          @sdk-init="handleSdkInitInstagram"
          :key="network_to_link"
          v-if="network_to_link === 'instagram'"
        >
        </v-facebook-login>
      </div>

        <b-button @click="youtubeLogin()" class="google-btn" v-if="network_to_link === 'youtube'">
          <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google-ico.png')}) transparent 5px 50% no-repeat;`"></span>
          <span class="google-text">{{ $t('login.googleLogin') }}</span>
        </b-button>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import service from "@/services/others";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import utils from "@/libs/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import social_service from '@/services/social'
import VFacebookLogin from 'vue-facebook-login-component'
import ModalVideosYoutube from "./ModalVideosYoutube.vue";
import ModalMediaInstagram from "./ModalMediaInstagram.vue";
import ConsentSpotify from './ConsentSpotify.vue'
import ModalBrandme from "@/views/components/modal/ModalBrandme.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BDropdown,
    BDropdownItem,
    VFacebookLogin,
    ModalVideosYoutube,
    ModalMediaInstagram,
    ConsentSpotify,
    ModalBrandme
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      utils,
      required,
      networks: [],
      newNetwork: {
        network: "",
        username: "",
        is_channel: false
      },
      netIconsWhite: {
        tiktok: require("@/assets/images/icons/tiktok.svg"),
        spotify: require("@/assets/images/icons/spotify.svg"),
        pinterest: require("@/assets/images/icons/pinterest.svg"),
        blog: require("@/assets/images/icons/blogger.svg"),
        snapchat: require("@/assets/images/icons/snapchat.svg"),
      },
      netIconsColor: {
        tiktok: require("@/assets/images/icons/tiktok2.svg"),
        spotify: require("@/assets/images/icons/spotify2.svg"),
        pinterest: require("@/assets/images/icons/pinterest2.svg"),
        blog: require("@/assets/images/icons/blog2.svg"),
        snapchat: require("@/assets/images/icons/snapchat2.svg"),
      },
      netOptions: [
        { value: "instagram", text: "Instagram" },
        { value: "youtube", text: "YouTube" },
        { value: "tiktok", text: "TikTok" },
        { value: "twitter", text: "Twitter" },
        { value: "facebook", text: "Facebook" },
        { value: "linkedin", text: "Linkedin" },
        { value: "spotify", text: "Spotify" },
        { value: "pinterest", text: "Pinterest" },
        { value: "twitch", text: "Twitch" },
        { value: "blog", text: "Blog" },
        { value: "snapchat", text: "Snapchat" },
      ],
      selected: null,
      selectTextDefault: "select",
      dropdownNetworks: [
        {
          value: "tiktok",
          network: "Tiktok",
          icon: require("@/assets/images/icons/tiktok2.svg"),
        },
        {
          value: "spotify",
          network: "Spotify",
          icon: require("@/assets/images/icons/spotify2.svg"),
        },
        {
          value: "snapchat",
          network: "Snapchat",
          icon: require("@/assets/images/icons/snapchat2.svg"),
        },
        {
          value: "blog",
          network: "Blog",
          icon: require("@/assets/images/icons/blog2.svg"),
        },
        {
          value: "pinterest",
          network: "Pinterest",
          icon: require("@/assets/images/icons/pinterest2.svg"),
        },

        {
          value: "linkedin",
          network: "LinkedIn",
          icon: require("@/assets/images/icons/linkedin2.svg"),
        },
        {
          value: "youtube",
          network: "Youtube",
          icon: require("@/assets/images/icons/youtube2.svg"),
        },
        {
          value: "instagram",
          network: "Instagram",
          icon: require("@/assets/images/icons/instagram2.svg"),
        },
        {
          value: "twitter",
          network: "Twitter",
          icon: require("@/assets/images/icons/twitter2.svg"),
        },
        {
          value: "twitch",
          network: "Twitch",
          icon: require("@/assets/images/icons/twitch2.svg"),
        },
        {
          value: "facebook",
          network: "Facebook",
          icon: require("@/assets/images/icons/facebook2.svg"),
        },
      ],
      valid_network: true,
      facebookOptions: {
        scope: 'email,user_link',
        return_scopes: true,
        auth_type: 'rerequest'
      },
      optionsButton: {
        cookie: true,
        xfbml: true,
        version: 'v14.0',
      },
      fb: {},
      scope_facebook: {},
      ig: {},
      scope_instagram: {},
      user_face: {},
      facebook_app_id: process.env.VUE_APP_FACEBOOK_APP_ID,
      videos_youtube: [],
      modal_videos: false,
      change_modal_videos: false,
      facebook_business_app_id: process.env.VUE_APP_FACEBOOK_BUSINESS_APP_ID,
      insta_face: {},
      instagramOptions: {
        scope: 'instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement',
        return_scopes: true,
        auth_type: 'rerequest'
      },
      instagram_medias: [],
      modal_instagram: false,
      change_modal_instagram: false,
      username_modal: '',
      modal_consent: false,
      change_modal_consent: false,
      username_validate: '',
      show_modal_username: false,
      change_modal_username: 0,
      network_to_link: '',
      show_modal_login: false,
      change_modal_login: 0,
      loaded: false,
    };
  },
  created() {
    localize("es");
    this.getNetworks();
    
    this.redeemToken()
    this.redeemTokenTiktok()
    this.redeemTokenYoutbe()
    this.redeemTokenLinkedin()
    this.redeemTokenTwitch()
    this.redeemTokenPinterest()
    this.redeemTokenSpotify()
    this.redeemTokenSnapchatt()
  },
  methods: {
    redirectNetwork(profile) {
      if (profile.network !== 'blog' && !profile.connected) {
        localStorage.setItem('user_to_link', profile.username)
        if (profile.network === 'facebook') {
          this.show_modal_login = true
          this.network_to_link = profile.network
          this.change_modal_login += 1
        } else if (profile.network === 'twitter') {
          this.redirectTwitter()
        } else if (profile.network === 'tiktok') {
          this.redirectTiktokLogin();
        } else if (profile.network === 'youtube') {
          this.show_modal_login = true
          this.network_to_link = profile.network
          this.change_modal_login += 1
        } else if (profile.network === 'linkedin') {
          this.redirectLinkedinLogin()
        } else if (profile.network === 'instagram') {
          this.show_modal_login = true
          this.network_to_link = profile.network
          this.change_modal_login += 1
        } else if (profile.network === 'twitch') {
          this.redirectTwitchLogin();
        } else if (profile.network === 'pinterest') {
          this.redirectPinterestLogin()
        } else if (profile.network === 'snapchat') {
          this.redirectSnapchatLogin()
        } else if (profile.network === 'spotify') {
          this.modal_consent = true
          this.change_modal_consent = !this.change_modal_consent
        }
      }
    },
    checkOauth() {
      return this.selected === 'twitter' || this.selected === 'tiktok' || this.selected === 'twitch' || this.selected === 'linkedin' || this.selected === 'pinterest' || this.selected === 'spotify'
    },
    netConnect() {
      const connect = this.networks.find(element => element.connected === true);
      if (connect === undefined) {
        return false
      }
      return true
    },
    checkNetworkConnect(network) {
      return network === 'twitter' || network === 'facebook' || network === 'youtube'  || network === 'tiktok' || network === 'linkedin' || network === 'twitch' || network === 'instagram' || network === 'pinterest' || network === 'spotify'
    },
    redirectPinterestLogin() {
      localStorage.setItem('network_code', 'pinterest')
      const client_id = process.env.VUE_APP_PINTEREST_ID
      const redirect_uri = process.env.VUE_APP_TWITCH_CALLBACK
      const scope = 'ads:read,boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read,catalogs:read,catalogs:write'
      const state = Math.random().toString(36).substring(2);
      const url = `https://www.pinterest.com.mx/oauth/?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}`
      window.location.href = url;
    },
    redirectSnapchatLogin() {
      localStorage.setItem('network_code', 'snapchat')
      const state = Math.random().toString(36).substring(2);
      const redirectUri = process.env.VUE_APP_TWITCH_CALLBACK;
      const clientId = process.env.VUE_APP_SNAPCHAT_ID;
      const scopeList = [
        'https://auth.snapchat.com/oauth2/api/user.display_name',
        'https://auth.snapchat.com/oauth2/api/user.bitmoji.avatar',
        'https://auth.snapchat.com/oauth2/api/user.external_id'
      ];
      const scope = scopeList.join(' ');

      const loginQS = new URLSearchParams({
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: 'code',
        scope,
        state,
      });

      const SNAP_ACCOUNTS_LOGIN_URL = 'https://accounts.snapchat.com/accounts/oauth2/auth';
      window.location.href = `${SNAP_ACCOUNTS_LOGIN_URL}?${loginQS}`
    },
    redeemTokenSnapchatt() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'snapchat') {
        this.$vs.loading()
        const form_data = new FormData()
        form_data.append('code', code)
        social_service.redeem_code('snapchat', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.$vs.loading.close()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
          })
      } else if (error && network_code === 'snapchat') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    redirectTwitchLogin() {
      localStorage.setItem('network_code', 'twitch')
      const state = Math.random().toString(36).substring(2);
      const client_id = process.env.VUE_APP_TWITCH_ID
      const redirect_uri = process.env.VUE_APP_TWITCH_CALLBACK
      const scope = 'analytics:read:games+bits:read+channel:manage:schedule+channel:manage:videos+channel:read:editors+channel:read:goals+channel:read:hype_train+channel:read:polls+channel:read:predictions+channel:read:redemptions+channel:read:subscriptions+moderation:read+user:read:email+user:read:follows+user:read:subscriptions'
      const url = `https://id.twitch.tv/oauth2/authorize?force_verify=true&response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`
      window.location.href = url;
    },
    redirectLinkedinLogin() {
      localStorage.setItem('network_code', 'linkedin')
      const state = Math.random().toString(36).substring(2);
      const client_id = process.env.VUE_APP_LINKEDIN_ID
      const redirect_uri = process.env.VUE_APP_LINKEDIN_CALLBACK

      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=r_liteprofile+r_emailaddress+w_member_social`
      window.location.href = url
    },
    redirectTiktokLogin() {
      localStorage.setItem('network_code', 'tiktok')
      const state = Math.random().toString(36).substring(2);
      const client_key = process.env.VUE_APP_CLIENT_KEY_TIKTOK
      const redirect_uri = process.env.VUE_APP_TIKTOK_CALLBACK
      const url = `https://www.tiktok.com/auth/authorize/?client_key=${client_key}&scope=user.info.basic,video.list&response_type=code&redirect_uri=${redirect_uri}&state=${state}`
      window.location.href = url;
    },
    redirectSpotifyLogin() {
      localStorage.setItem('network_code', 'spotify')
      const state = Math.random().toString(36).substring(2);
      const client_key = process.env.VUE_APP_SPOTIFY_ID
      const redirect_uri = process.env.VUE_APP_LINKEDIN_CALLBACK
      const scope = 'user-read-private user-read-email' // user-follow-read user-read-currently-playing user-top-read user-library-read
      const url = `https://accounts.spotify.com/authorize?response_type=code&client_id=${client_key}&scope=${scope}&redirect_uri=${redirect_uri}&state=${state}`
      window.location.href = url;
    },
    showToast(title, text, variant, icon = 'AlertIcon', position = 'top-right') {
      this.$toast({
        component: ToastificationContent,
        position,
        props: {
          title,
          icon,
          variant,
          text,
        },
      });
    },
    redeemTokenSpotify() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'spotify') {
        this.$vs.loading()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))
        social_service.redeem_code('spotify', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.$vs.loading.close()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
          })
      } else if (error && network_code === 'pinterest') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    redeemTokenPinterest() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'pinterest') {
        this.$vs.loading()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))
        social_service.redeem_code('pinterest', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.$vs.loading.close()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
          })
      } else if (error && network_code === 'pinterest') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    redeemTokenTwitch() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'twitch') {
        this.$vs.loading()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))
        social_service.redeem_code('twitch', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.$vs.loading.close()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
          })
      } else if (error && network_code === 'twitch') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    redeemTokenLinkedin() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'linkedin') {
        this.$vs.loading()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))

        social_service.redeem_code('linkedin', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }

            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
            this.$vs.loading.close()
          })
      } else if (error && network_code === 'linkedin') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )
        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    redeemTokenTiktok () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'tiktok') {
        this.$vs.loading()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))

        social_service.redeem_code('tiktok', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
            this.$vs.loading.close()
          })
      } else if (error && network_code === 'tiktok') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )
        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    redeemTokenYoutbe () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'youtube') {
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))

        social_service.redeem_code('youtube', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('socialErrorTitle'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: this.$t('socialError'),
                },
              });
            } else if (response.code && response.code === 'errorYoutube') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('socialErrorTitle'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: this.$t('socialErrorYoutube'),
                },
              });
            } else {
              this.getNetworks();
              if (response.new) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialConnected'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: this.$t('socialSuccess'),
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialAlreadyConnected'),
                    icon: 'AlertIcon',
                    variant: 'warning',
                    text: this.$t('socialAlreadySuccess'),
                  },
                });
              }
            }
            
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/Social')
            }, 50)
          })
      } else if (error && network_code === 'youtube') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('deniedOauthTitle'),
            icon: 'AlertIcon',
            variant: 'danger',
            text: this.$t('deniedOauth'),
          },
        });

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    youtubeLogin() {
      localStorage.setItem('network_code', 'youtube')
      const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

      const options = {
        client_id: process.env.VUE_APP_GOOGLE_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_CALLBACK,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: 'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly',
        hl: 'en'
      };

      const qs = new URLSearchParams(options);

      window.location.href = `${rootUrl}?${qs.toString()}`
    },
    getSrcIconColor(network) {
      const index_network = Object.keys(this.netIconsColor).indexOf(network)

      if (index_network === -1) return

      return Object.values(this.netIconsColor)[index_network]
    },
    checkToken(profile) {
      this.$vs.loading()
      social_service.validate_token(profile.uuid)
        .then(response => {
          this.$vs.loading.close()
          if (!response.valid_token) {
            if (profile.network === 'facebook') {
              this.$vs.loading()
              this.makeLogin()
            } else if (profile.network === 'twitter') {
              this.redirectTwitter()
            } else if (profile.network === 'tiktok') {
              this.redirectTiktokLogin();
            } else if (profile.network === 'youtube') {
              this.youtubeLogin()
            } else if (profile.network === 'linkedin') {
              this.redirectLinkedinLogin()
            } else if (profile.network === 'instagram') {
              this.makeLoginInstagram();
            } else if (profile.network === 'twitch') {
              this.redirectTwitchLogin();
            } else if (profile.network === 'pinterest') {
              this.redirectPinterestLogin()
            } else if (profile.network === 'snapchat') {
              this.redirectSnapchatLogin()
            } else if (this.selected === 'spotify') {
              this.modal_consent = true
              this.change_modal_consent = !this.change_modal_consent
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('profile.permissionsActiveTitle'),
                icon: 'AlertIcon',
                variant: 'success',
                text: this.$t('profile.permissionsUpdated'),
              },
            });
          }
        })
    },
    handleSdkInit({ FB, scope }) {
      this.fb = FB
      this.scope_facebook = scope
    },
    handleSdkInitInstagram({ FB, scope}) {
      this.ig = FB
      this.scope_instagram = scope
    },
    oauth() {
      if (this.selected === 'twitter') {
        this.redirectTwitter()
      } else if (this.selected === 'facebook') {
        this.$vs.loading()
        this.makeLogin()
      } else if (this.selected === 'twitch') {
        this.redirectTwitchLogin();
      } else if (this.selected === 'linkedin') {
        this.redirectLinkedinLogin();
      } else if (this.selected === 'tiktok') {
        this.redirectTiktokLogin();
      } else if (this.selected === 'pinterest') {
        this.redirectPinterestLogin();
      } else if (this.selected === 'snapchat') {
        this.redirectSnapchatLogin();
      } else if (this.selected === 'spotify') {
        this.modal_consent = true
        this.change_modal_consent = !this.change_modal_consent
      }
    },
    makeLogin () {
      const self = this
      this.fb.login(function(response) {
        if (response.authResponse) {
          self.getUserDataFacebook(response)
        } else {
          self.$vs.loading.close()
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: self.$t('deniedOauthTitle'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: self.$t('deniedOauth'),
            },
          });
        }
      }, self.facebookOptions);
    },
    makeLoginInstagram () {
      const self = this
      this.ig.login(function(response) {
        if (response.authResponse) {
          self.getUserDataInstagram(response)
        } else {
          self.$vs.loading.close()
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: self.$t('deniedOauthTitle'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: self.$t('deniedOauth'),
            },
          });
        }
      }, self.instagramOptions);
    },
    getUserDataInstagram(data) {
      if (!data || !data.authResponse) return
      this.$vs.loading()
      const form_data = new FormData()
      form_data.append('access_token', data.authResponse.accessToken)
      form_data.append('signed_request', data.authResponse.signedRequest)
      form_data.append('user_id', data.authResponse.userID)
      form_data.append('username', localStorage.getItem('user_to_link'))
      this.$vs.loading()
      social_service.save_token_instagram(form_data)
        .then(() => {
          this.show_modal_login = false
          this.network_to_link = ''
          this.change_modal_login += 1
          const self = this
          setTimeout(function () {
            self.getNetworks();
            self.$vs.loading.close()
          }, 1000)
        })
    },
    getUserDataFacebook(data) {
      if (!data || !data.authResponse) return
      this.$vs.loading()
      const form_data = new FormData()
      form_data.append('access_token', data.authResponse.accessToken)
      form_data.append('signed_request', data.authResponse.signedRequest)
      form_data.append('user_id', data.authResponse.userID)
      form_data.append('username', localStorage.getItem('user_to_link'))

      social_service.save_token_facebook(form_data)
        .then(response => {
          this.show_modal_login = false
          this.network_to_link = ''
          this.change_modal_login += 1
          this.$vs.loading.close()
          this.scope_facebook.logout()
          if (response.code && response.code === 'accountNetwrokExists') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('socialErrorTitle'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: this.$t('socialError'),
              },
            });
          } else if (response.code) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('socialErrorTitle'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: this.$t('tryAgain'),
              },
            });
          } else {
            this.getNetworks();
            if (response.new) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('socialConnected'),
                  icon: 'AlertIcon',
                  variant: 'success',
                  text: this.$t('socialSuccess'),
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('socialAlreadyConnected'),
                  icon: 'AlertIcon',
                  variant: 'warning',
                  text: this.$t('socialAlreadySuccess'),
                },
              });
            }
          }
        })
      this.$vs.loading.close()
    },
    redirectTwitter() {
      this.$vs.loading()
      social_service.get_url_login_twitter()
        .then(response => {
          const url = response.url
          localStorage.setItem('token_twitter', response.temp_secret);
          window.location.href = url;
        })
    },
    redeemToken() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const oauth_key = urlParams.get('oauth_token')
      const oauth_token = localStorage.getItem('token_twitter') ? localStorage.getItem('token_twitter') : 'xxx'
      const oauth_verifier = urlParams.get('oauth_verifier')
      const denied = urlParams.get('denied')
      const username = localStorage.getItem('user_to_link')

      if (oauth_key && oauth_verifier) {
        this.$vs.loading()
        social_service.redeem_token_twitter(oauth_key, oauth_token, oauth_verifier, username)
          .then(response => {
            localStorage.removeItem('token_twitter');
            this.$vs.loading.close()

            if (response.code && response.code === 'accountNetwrokExists') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('socialErrorTitle'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: this.$t('socialError'),
                },
              });
            } else if (response.code) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('socialErrorTitle'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: this.$t('tryAgain'),
                },
              });
            } else {
              if (response.new) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialConnected'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: this.$t('socialSuccess'),
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialAlreadyConnected'),
                    icon: 'AlertIcon',
                    variant: 'warning',
                    text: this.$t('socialAlreadySuccess'),
                  },
                });
              }
              this.getNetworks();
              localStorage.removeItem('network_code');
              const self = this
              setTimeout(function () {
                self.$router.push('/pages/account-setting/Social')
              }, 50)
            }
          })
      } else if (denied) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('deniedOauthTitle'),
            icon: 'AlertIcon',
            variant: 'danger',
            text: this.$t('deniedOauth'),
          },
        });
        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/Social')
        }, 50)
      }
    },
    resetForm() {
      this.newNetwork = {
        network: "",
        username: "",
        is_channel: false
      };
    },
    getNetworks() {
      service.getSocialNetworks().then((response) => {
        this.networks = response.results;
        this.loaded = true;
      })
        .catch(() => {
        });
    },
    dropdownItemClick(net) {
      this.selected = net.value
      this.selectTextDefault = net.network

      if (this.selected === 'facebook') {
        this.fb.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v14.0'
        });
      } else if (this.selected === 'instagram') {
        this.ig.init({
          appId: process.env.VUE_APP_FACEBOOK_BUSINESS_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v14.0'
        });
      }

      if (this.selected === 'facebook' || this.selected === 'twitter' || this.selected === 'tiktok' || this.selected === 'youtube' || this.selected === 'linkedin' || this.selected === 'twitch' || this.selected === 'instagram' || this.selected === 'pinterest' || this.selected === 'spotify') this.valid_network = false
      else this.valid_network = true
    },
    cancelConfirmUsername() {
      this.show_modal_username = false
      this.change_modal_username += 1
    },
    confirmUsername() {
      this.newNetwork.username = this.username_validate
      this.addNetwork()
    },
    addNetwork() {
      this.show_modal_username = false
      this.change_modal_username += 1
      this.newNetwork.network = this.selected;
      let username = ''

      this.$refs.newNetForm.validate().then((valid) => {
        if (valid) {
          if (utils.isUrl(this.newNetwork.username)) {
            if (this.selected === 'instagram') {
              username = utils.usernameByUrl(this.newNetwork.username, 'instagram.com/')
            } else if (this.selected === 'tiktok') {
              username = utils.usernameByUrl(this.newNetwork.username, 'tiktok.com/@')
            } else if (this.selected === 'spotify') {
              username = utils.usernameByUrl(this.newNetwork.username, 'open.spotify.com/user/')
            } else if (this.selected === 'pinterest') {
              username = utils.usernameByUrl(this.newNetwork.username, 'pinterest.com.mx/')
            } else if (this.selected === 'twitter') {
              username = utils.usernameByUrl(this.newNetwork.username, 'twitter.com/')
            } else if (this.selected === 'snapchat') {
              username = utils.usernameByUrl(this.newNetwork.username, 'snapchat.com/add/')
            } else if (this.selected === 'twitch') {
              username = utils.usernameByUrl(this.newNetwork.username, 'twitch.tv/')
            } else if (this.selected === 'facebook') {
              username = utils.usernameByUrl(this.newNetwork.username, 'facebook.com/')
            } else if (this.selected === 'linkedin') {
              username = utils.usernameByUrl(this.newNetwork.username, 'linkedin.com/in/')
            } else if (this.selected === 'youtube') {
              const result = utils.getUsernameUrlYoutube(this.newNetwork.username)
              username = result[0]
              this.newNetwork.is_channel = result[1]
            }

            if (username) {
              this.username_validate = username;
              this.show_modal_username = true
              this.change_modal_username += 1
              return
            } else if (this.selected !== 'blog') {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: 'Error',
                  icon: "AlertIcon",
                  variant: "danger",
                  text: 'No se pudo obtener el usuario de la url',
                },
              });
              return
            }
          }

          service.addSocialNetwork(this.newNetwork).then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.new) {
              this.resetForm();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("socialAdd"),
                  icon: "AlertIcon",
                  variant: "success",
                  text: this.$t("socialSuccess"),
                },
              });
              this.getNetworks();
            } else {
              this.showToast(
                this.$t('socialAlreadyConnected'),
                this.$t('socialAlreadySuccess'),
                'warning'
              )
            }
          });
        }
      });
    },
    deleteNetwork(network) {
      const self = this
      self.$swal({
        title: this.$t('confirmDeleteNetworkTitle'),
        text: this.$t('confirmDeleteNetwork'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('msjConfirmNetwork'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        reverseButtons: true,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          service.removeSocialNetwork(network.uuid).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("socialDeleted"),
                icon: "AlertIcon",
                variant: "danger",
                text: this.$t("socialDeleteddata"),
              },
            });
            this.getNetworks();
          });
        }
      })
    },
    capitalString(string) {
      return utils.capitalize(string);
    },
    getUrl(profile) {
      if (profile.network === 'facebook' && profile.link) return profile.link
    
      return utils.getURL(profile.network, profile.username, profile.is_channel);
    },
    showDetailYoutube(profile) {
      this.$vs.loading()
      social_service.get_info_youtube(profile.uuid)
        .then(response => {
          this.$vs.loading.close()
          if (response.length > 0) {
            this.videos_youtube = response
            this.modal_videos = true
            this.change_modal_videos = !this.change_modal_videos
          } else {
            const url = `https://www.youtube.com/channel/${profile.username}`
            window.open(url, '_blank')
          }
        })
    },
    showMediaInstagram(profile) {
      this.$vs.loading()
      social_service.get_media_instagram(profile.uuid)
        .then(response => {
          this.$vs.loading.close()
          if (response.length > 0) {
            this.instagram_medias = response
            this.modal_instagram = true
            this.change_modal_instagram = !this.change_modal_instagram
            this.username_modal = profile.username
          } else {
            const url = `https://www.instagram.com/${profile.username}`
            window.open(url, '_blank')
          }
        })
    }
  },
};
</script>

<style>
.select-network{
  margin-top: 24px;
}
.container-check-icon {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #28c76f;
  justify-content: end;
  position:absolute;
  right: -13px;
  top: -3px;
  padding: 0px;
  transform: rotate(225deg);
}
.container-icon-alert {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ff9f43;
  justify-content: end;
  position:absolute;
  right: -13px;
  top: -3px;
  padding: 0px;
  transform: rotate(225deg);
}
 .profile-username{
    height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .v-facebook-login{
    background:#3578E5 !important;
  }
  .login-facebook{
    display: flex;
    justify-content: center;
  }
  .icon-alert{
    font-size:17px;
    justify-content: end;
    position:absolute;
    right: -1px;
    top: -2px;
    height: 20px;
    padding: 0px;
    color:white;
  }
  .check-icon{
    font-size:17px;
    justify-content: end;
    position:absolute;
    right: -1px;
    top: -2px;
    height: 20px;
    padding: 0px;
    color:white;
  }
  .button-link{
    height: 40px;
    width: 130px;
    margin-top: 3px
  }
  .animation-connect{
    display: flex;
    justify-content: center !important;
    flex-wrap: nowrap !important; 
    align-items: center !important ;
    height: 100%;
  }
  .content-animation{
    display:none;
  }
  .link-profile{
    padding:3px;
    display:none !important;
    height: 25px;
    width: 25px !important;
    border-radius: 10%;
    color:white;
    cursor: pointer !important;
  }
  .icon-plus{ 
    cursor:pointer;
    font-size: 45px;
    color: white !important;
    justify-content: end;
    margin: 20px 0px 10px 0px
  }
  .icon-plus{ 
    cursor:pointer;
    font-size: 45px;
    color: white !important;
    justify-content: end;
    margin: 20px 0px 10px 0px
  }
  .text-plus{
    font-size: 18px;
    color: white !important;
  }
  .size-text-msj{
    margin-top:10px;
    font-size: small;
  }
  .net-social-message{
    padding: 20px;
    text-align: center;
    display: flex;
  }
  .social-message-disconnected{
    padding: 20px;
    text-align: justify;
    display: block;
    font-size: medium;
  }
  .iconDropdown{
    width: 18px; margin-right:5px; margin-top:-2px
  }
  .label{
    margin-top:3px;
    margin-bottom: 3.5px;
  }
  .dropdown-net{
    border: 1px solid #D8D6DE;
  }
  .dropdown-net:focus-within{
    border: none;
    border: 1px solid #7367F0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  
  }
  .network-name-connected{
    font-weight: bold;
    text-align: start;
    font-size:x-small;
    margin-top: 15px;
    margin-bottom: 0rem;
    color: white;
  }
  .network-name-disconnected{
    font-weight: bold;
    text-align: start;
    font-size:x-small;
    margin-top: 15px;
    margin-bottom: 0rem;
    color: white;
  }
  .user-name-connected{
    font-weight: bold;
    text-align: start;
    font-size: small;
    margin: 0px;
    color:white !important;
  }
  .user-name-disconnected{
    font-weight: bold;
    text-align: start;
    font-size: small;
    margin: 0px;
    color: white !important;
  }
  .size-x-connected{
    padding: 4px;
    display:none !important;
    height: 25px;
    width: 25px !important;
    border-radius: 10%;
    color:white;
    cursor: pointer !important;
  }
  .size-x-disconnected{
    display:none;
    font-size:15px;
    justify-content: end;
    position:absolute;
    right: 5px;
    top:2px;
    color: white;
    cursor: pointer !important;
  }
  .size-check-connected{
    display:none !important;
    height: 25px;
    width: 80px;
    justify-content: center;
    align-items: center;
    /* background-color:   #57daac !important;
    border: #57daac !important;
    color:white; */
    cursor: pointer !important;
  }
  .icon-connected{
    font-size: 32px;
    color: #fff !important;
    text-align: start;
    margin: 7px 0px 10px 0px;
  }
  .icon-disconnected{
    font-size: 32px;
    color: white !important;
    text-align: start;
    margin: 7px 0px 20px 0px;
  }
   .size-card{ 
    max-width: 15.8rem;
    min-width: 10.4rem;
    height: 10.4rem;
    padding: 10px;
    background-color: #C8C8C8;
    border-radius: 5% !important;
    overflow: hidden;
  }
  .size-card:hover .text-plus{ 
    animation: text-animation .3s;
  }
  .size-card:hover .icon-plus{ 
    animation: icon-animation .3s;
  }
  .size-card:hover .container-icon-alert{
    display:none;
  }
  .size-card:hover .container-check-icon{
    display:none;
  }
  .size-card:hover .icon-alert{
    display:none;
  }
  .size-card:hover .check-icon{
    display:none;
  }
  .size-card:hover .icon-disconnected{ 
    display:none;
  }
  .size-card:hover .user-name-disconnected{ 
    display:none;
  }
  
  .size-card:hover .network-name-disconnected{ 
    display:none;
  }
  
  .size-card:hover .network-name-connected{ 
    display:flex;
    margin-top: 5px;
   
  }
  .size-card:hover .content-animation{ 
    display:block;
  }
  .size-card:hover .size-x-connected{ 
    display:flex !important;
    animation: icon-animation .3s;
  }
  .size-card:hover .size-x-disconnected{ 
    display:block !important;
  }
  .size-card:hover .size-check-connected{ 
    display:flex !important;
    animation: icon-animation .3s;
  }
  .size-card:hover .link-profile{ 
    display:flex !important;
    animation: icon-animation .3s;
  }
  
  .dropdown-icon-social-settings {
    width: 20px;
    margin-right: 1em;
  }

  .button-dropdown > button {
    padding: 7px;
  }
  
  @media(max-width:767px){
    .submenu{
      margin-top:514px ;
    }
  }  
  @media(max-width:400px){
    .size-card{ 
      min-width: 14.4rem;
    }
    .responsive-xs{ 
      display: block;
    }
  }  
  @keyframes icon-animation {
  0%   {transform: translateY(-50px);}
  100%   {transform: translateY(0px);}
}
@keyframes text-animation {
  0%   {transform: translateY(50px);}
  100%   {transform: translateY(0px);}
}

</style>